import React from "react";
import Layout from "../../areas/main/components/Layout";
import NavOne from "../../areas/main/components/NavOne";
import Texts from "../../utils/lang-utils";
import Intro from "./components/Intro";
import Why from "./components/Why";
import HowItWorks from "./components/HowItWorks";
import Loyalty from "./components/Loyalty";
import Features from "./components/Features";
import Payments from "./components/Payments";
import Foodstagram from "./components/Foodstagram";
import Footer from "../../areas/main/components/Footer"
import Outro from "./components/Outro";

export default () => { 

return <Layout pageTitle={Texts.siteTitle} language="en">
    <NavOne catchScroll={true} />
    <Intro />
    <Why />
    <HowItWorks />
    <Features />
    <Payments />
    <Loyalty />
    <Foodstagram />
    <Outro />
    <Footer />
</Layout>};
import React from "react";
import Texts from "../../../utils/lang-utils";
import './Loyalty.scss';
import urls from "../../../areas/main/assets/urls";

const Loyalty = () => {
  return (
    <section className="cta-one loyalty">
      <div className="container">

        <div className="row justify-content-lg-end">
          <div className="col-lg-12">
            <div className="cta-one__content">
              <i className="cta-one__icon dimon-icon-data1"></i>
              <div className="block-title text-left text-center">
                <h2 className="block-title__title " dangerouslySetInnerHTML={{ __html: Texts.QROrdering.LoyaltyTitle}} />
              </div>
              <div >
                <p  dangerouslySetInnerHTML={{ __html: Texts.QROrdering.LoyaltyText1}}>
                </p>

                <div className="cta-one__text">
                <p className="text-center" dangerouslySetInnerHTML={{ __html: Texts.QROrdering.LoyaltySubtitle}}>

                </p>
                </div>

                <p className="text-center"  dangerouslySetInnerHTML={{ __html: Texts.QROrdering.LoyaltyText2}}>
                </p>

              </div>

                <div className="text-center"><br></br>
                  <a href={urls.onboarding} className="thm-btn">
                  <span>{Texts.GetStarted}</span>
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Loyalty;
